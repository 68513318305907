.centered {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    &.large {
        font-size: 20px;
    }

    &.extreme {
        font-size: 40px;
    }
}
