@import "../../scss/variables";

.Home {
    display: flex;
    flex-direction: column;
    background: linear-gradient(
            rgba(0, 0, 0, $background-opacity),
            rgba(0, 0, 0, $background-opacity)
        ),
        url("/assets/main-bg.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    text-align: center;

    .error {
        color: red;
    }

    .search-area {
        width: 100%;
        max-width: 960px;
        margin: 0 auto;
        padding: 0 20px;
        .search-title {
            background: $main-background;
            font-size: 30px;
        }
    }

    .logo {
        width: 200px;
        min-height: 200px;
        object-fit: fill;
        background: url("/assets/logo.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        margin: 20px auto;
    }

    .search {
        flex: 1;
        overflow: auto;
    }
}

@media only screen and (max-width: 1000px) {
    .Home {
        .search-area {
            width: 100%;
            padding: 0 20px;
            margin-top: 10px;
            .search-title {
                font-size: 25px;
                height: 50px;
            }
        }
    }
}

@media only screen and (max-height: 900px) {
    .logo.hidden {
        display: none;
    }

    .search-area {
        margin: 20px auto 0 auto !important;
    }
}
