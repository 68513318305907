@import "../../scss/variables";

.not-found-404 {
    background: linear-gradient(
            rgba(0, 0, 0, $background-opacity),
            rgba(0, 0, 0, $background-opacity)
        ),
        url("/assets/main-bg.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 100vh;

    h1 {
        font-size: 50px;
        text-align: center;
    }

    h2 {
        font-size: 30px;
        text-align: center;
    }
    p {
        margin-top: 20px;
    }
}
