@import "../../../scss/variables";

pre {
    padding: 0;
    margin: 0;
}

.media-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.media {
    display: grid;
    grid-template-areas:
        "poster title title"
        "poster content content";

    grid-template-columns: 185px 1fr 1fr;
    grid-template-rows: auto 1fr;

    gap: 10px;

    width: 900px;
    margin: 0 auto;
    background: rgba($main-background, 0.8);
    padding: 20px;
    overflow: auto;

    .poster {
        grid-area: poster;
    }

    .media-title {
        grid-area: title;
        margin-bottom: 0;
    }

    .content {
        grid-area: content;
        max-width: 600px;
        display: flex;
        flex-direction: column;

        .categories {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            justify-items: center;
        }
    }

    &-overview {
        margin: 15px 0;
    }

    .ant-collapse {
        .ant-collapse-content {
            background-color: #333;
        }
    }
}

@media only screen and (max-width: 900px) {
    .media {
        width: 100%;

        grid-template-areas:
            "title"
            "poster"
            "content";
        grid-template-columns: 1fr;

        .poster {
            display: flex;
            justify-content: center;
        }

        .media-title {
            text-align: center;
        }

        .content {
            max-width: unset;
            justify-content: center;
        }
    }
}
