.search-grid {
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow: auto;
    margin: 0 auto;
    max-width: 960px;
}
