@import "../../scss/variables";

.movie-result {
    position: relative;
    display: flex;
    text-align: center;
    background: rgba($main-background, 0.6);
    border-radius: 5px;
    margin: 5px 10px;
    padding: 10px;
    z-index: 1;
    margin-left: 20px;

    &.working {
        opacity: 0.5;
    }

    &__details {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        &__title {
            text-align: left;
            font-weight: 600;
            font-size: 30px;
            margin-bottom: 10px;
        }

        &__overview {
            text-align: left;
        }
    }

    &__poster {
        display: inline-flex;
        flex-direction: column;

        .media-type-tag {
            margin-top: 5px;
        }
    }

    .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
    }
}

@media only screen and (max-width: 1000px) {
    .movie-result {
        margin: 5px 20px;
        &__details {
            margin: 0 0 0 10px;

            &__title {
                font-size: 20px;
            }
        }
    }
}
