@import "../../scss/variables";
.header {
    height: 50px;
    background-color: $main-background;
    display: flex;
    justify-content: center;
    align-items: center;

    .input-wrapper {
        width: 800px;
    }

    input.ant-input {
        font-size: 20px;
        text-align: center;
    }
}
