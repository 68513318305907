body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background-color: #202020;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

html,
body,
#root,
.app,
.Home {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
}

* {
    box-sizing: border-box;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.capitalize-first-letter {
    text-transform: capitalize;
}
